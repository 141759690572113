import SvgIcon from '@material-ui/core/SvgIcon';

function HomeIcon(props) {
    return (
      <SvgIcon {...props} viewBox="0 0 492.664 492.664" >
		<path d="M246.332,0C110.286,0,0,110.286,0,246.332c0,136.045,110.286,246.332,246.332,246.332s246.332-110.287,246.332-246.332
			C492.664,110.286,382.378,0,246.332,0z M246.332,448.686c-111.581,0-202.354-90.773-202.354-202.354
			c0-111.578,90.773-202.354,202.354-202.354s202.354,90.773,202.354,202.354S357.913,448.686,246.332,448.686z"/>
		<path d="M141.828,186.987H91.147c-5.008,13.052-8.429,26.875-9.994,41.258h56.5C138.299,214.132,139.709,200.32,141.828,186.987z"
			/>
		<path d="M110.47,150.812h39.201c4.32-15.437,9.689-29.842,16.085-42.789c1.669-3.38,3.413-6.587,5.188-9.716
			C146.771,110.672,126.015,128.77,110.47,150.812z"/>
		<path d="M350.836,305.678h50.681c5.009-13.052,8.43-26.875,9.992-41.258h-56.497C354.366,278.532,352.955,292.344,350.836,305.678
			z"/>
		<path d="M355.012,228.245h56.5c-1.562-14.383-4.984-28.206-9.992-41.258h-50.682C352.955,200.32,354.366,214.132,355.012,228.245z
			"/>
		<path d="M326.909,384.639c-1.67,3.381-3.414,6.588-5.188,9.717c24.176-12.361,44.93-30.459,60.475-52.502h-39.2
			C338.674,357.285,333.304,371.693,326.909,384.639z"/>
		<path d="M342.99,150.812h39.201c-15.545-22.043-36.3-40.141-60.475-52.502c1.775,3.129,3.521,6.335,5.188,9.715
			C333.304,120.971,338.674,135.379,342.99,150.812z"/>
		<path d="M149.673,341.854H110.47c15.545,22.043,36.299,40.141,60.475,52.502c-1.775-3.129-3.52-6.336-5.188-9.717
			C159.36,371.693,153.991,357.285,149.673,341.854z"/>
		<path d="M137.653,264.42h-56.5c1.563,14.383,4.983,28.206,9.991,41.258h50.682C139.709,292.344,138.299,278.532,137.653,264.42z"
			/>
		<path d="M264.42,86.158v64.654h40.894C294.986,119.049,280.05,96.339,264.42,86.158z"/>
		<path d="M187.352,150.812h40.894V86.158C212.614,96.339,197.677,119.049,187.352,150.812z"/>
		<path d="M178.435,305.678h49.811V264.42H173.85C174.569,278.99,176.15,292.788,178.435,305.678z"/>
		<path d="M173.849,228.245h54.396v-41.258h-49.811C176.15,199.876,174.569,213.674,173.849,228.245z"/>
		<path d="M264.42,186.987v41.258h54.396c-0.722-14.571-2.306-28.369-4.587-41.258H264.42z"/>
		<path d="M264.42,305.678h49.811c2.281-12.887,3.865-26.686,4.587-41.258h-54.396L264.42,305.678L264.42,305.678z"/>
		<path d="M228.245,406.507v-64.653h-40.893C197.677,373.616,212.614,396.326,228.245,406.507z"/>
		<path d="M264.42,406.507c15.63-10.179,30.566-32.889,40.894-64.653H264.42V406.507z"/>
      </SvgIcon>
    );
  }

  export default HomeIcon
