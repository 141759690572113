import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import styled from '@emotion/styled'
import { Link, navigate } from 'gatsby'

import SEO from './seo'

import ButtonAppBar from './topbar'
import { Card, CardContent, CardActions, IconButton, Button, Chip } from '@material-ui/core'
import RedditIcon from '@material-ui/icons/Reddit';
import FacebookIcon from '@material-ui/icons/Facebook';
import TelegramIcon from '@material-ui/icons/Telegram';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import EmailIcon from '@material-ui/icons/Email';
import HelpIcon from '@material-ui/icons/Help';

const Header = styled.div`
  max-width: 825px;
  margin: 0px 0px 10px 0px;
  width: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3 {
    margin: 0;
    color: #0a0a0a;
  }
`

const FakeBody = styled.div`
    margin: 20px auto;
    text-align: left;
    max-width: 800px;
    width: auto;

    @media only screen and (max-width: 1024px) {
      margin: 0px 0px 10px 0px;
    }
`

const MainBody = styled.div`
    max-width: 825px;
    margin-left: 8px;
    margin-right: 8px;
`

const Footer = styled.div`
    margin-top: 16px;
    max-width: 800px;
    text-align: center;
`

const TemplateWrapper = ({ children }) => (
  <Fragment>
    <Helmet>
      <script>
        {`
          (function(m,a,i,l,e,r){ m['MailerLiteObject']=e;function f(){
          var c={ a:arguments,q:[]};var r=this.push(c);return "number"!=typeof r?r:f.bind(c.q);}
          f.q=f.q||[];m[e]=m[e]||f.bind(f.q);m[e].q=m[e].q||f.q;r=a.createElement(i);
          var _=a.getElementsByTagName(i)[0];r.defer=true;r.src=l+'?v'+(~~(new Date().getTime()/1000000));
          _.parentNode.insertBefore(r,_);})(window, document, 'script', 'https://static.mailerlite.com/js/universal.js', 'ml');
          
          var ml_account = ml('accounts', '2059188', 'f5y7h7x3d3', 'load');
        `}
      </script>
      <script>
        {`
          var _smartsupp = _smartsupp || {};
          _smartsupp.offsetX = 30;
          _smartsupp.key = 'bb7f32508a638548ae6528adb3a2e46e4ab9e51f';
          window.smartsupp||(function(d) {
            var s,c,o=smartsupp=function(){ o._.push(arguments)};o._=[];
            s=d.getElementsByTagName('script')[0];c=d.createElement('script');
            c.type='text/javascript';c.charset='utf-8';c.defer=true;
            c.src='https://www.smartsuppchat.com/loader.js?';s.parentNode.insertBefore(c,s);
          })(document);
        `}
      </script>
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9152097609554171" crossorigin="anonymous"></script>
    </Helmet>
    <SEO />
    <main>
      <FakeBody>
      <MainBody>
        <Header>
          <ButtonAppBar/>
        </Header>
        {children}
        <Footer>
          <Card >
            <CardContent>
              Never miss a new job offer by following us on your favorite social media platform!
            </CardContent>
            <CardActions disableSpacing style={{justifyContent: 'center', }}>
              <a href="https://twitter.com/workwithvisa">
                <IconButton aria-label="Twitter" color="primary">
                  <TwitterIcon fontSize="small" />
                </IconButton>
              </a>
              <a href="https://www.linkedin.com/showcase/workwithvisa">
                <IconButton aria-label="LinkedIn" color="primary">
                  <LinkedInIcon fontSize="small" />
                </IconButton>
              </a>
              <a href="https://t.me/workwithvisa">
                <IconButton aria-label="Telegram" color="primary">
                  <TelegramIcon fontSize="small" />
                </IconButton>
              </a>
              <a href="https://facebook.com/workwithvisa">
                <IconButton aria-label="Facebook" color="primary">
                  <FacebookIcon fontSize="small" />
                </IconButton>
              </a>
              <a href="https://reddit.com/r/workwithvisa">
                <IconButton aria-label="Reddit" color="primary">
                  <RedditIcon fontSize="small" />
                </IconButton>
              </a>
              <a href="mailto:info@workwithvisa.com">
                <IconButton aria-label="Email" color="primary">
                  <EmailIcon fontSize="small" />
                </IconButton>
              </a>
            </CardActions>
            <CardContent>
              <Chip
                href="/about"
                clickable
                icon={<HelpIcon />}
                label="About us"
                color="primary"
                variant="outlined"
                onClick={(event) => {navigate("/about")}}
              />
            </CardContent>
            <CardContent>
              Copyright © Tweaked.Tech 2022
            </CardContent>
          </Card>
        </Footer>
      </MainBody>
      </FakeBody>
    </main>
  </Fragment>
)

export default TemplateWrapper