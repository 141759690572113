import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Link, navigate } from "gatsby"
import HomeIcon from './homeicon';
import { Hidden } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(1),
  },
  title: {
  },
  titleLink: {
    color: 'inherit',
    textDecoration: 'inherit',
  },
  beta: {
    alignSelf: 'start',
    background: "#EE8E4A",
    fontFamily: "sans-serif",
    color: "#3f51b5",
    fontSize: "8px",
    fontWeight: "bold",
    padding: "1px",
    borderRadius: "4px",
    background: "white"
  },
  break : {
    flexBasis: "100%",
    height: 0,
  },
  motto: {
    flexGrow: 1,
  },
  toolbarExtra: {
    flexDirection: "row",
    flexWrap: "wrap"
  },
  mottoAndTitleColumn: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    flexGrow: 1,
  },
  row: {
    flexDirection: "row",
    flexWrap: "wrap",
    display: "flex",
  },
  postJobButton: {
    background: "#ff424d",
    textTransform: "none",
    margin: "4px",
  },
  relocationButton: {
    background: "#ff9542",
    textTransform: "none",
    margin: "4px",
  },
  noLink: {
    textDecoration: "none",
    color: "inherit",
  }

}));

export default function ButtonAppBar() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar className={classes.toolbarExtra}>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={(event) => {navigate("/")}}>
            <HomeIcon />
          </IconButton>
          <div className={classes.mottoAndTitleColumn} >
            <div className={classes.row} >
              <Link to="/" className={classes.titleLink}> 
                <Typography variant="h6" className={classes.title}>
                  Work with Visa
                </Typography>
              </Link>
              <Typography variant="span" className={classes.beta}>
                BETA
              </Typography>
            </div>
            <Hidden xsDown>
              <div className={classes.break} />
              <div className={classes.motto}>Knowledge Knows No Boundaries</div>
            </Hidden>
          </div>
          <Link className={classes.noLink} to="/post_job">
            <Button className={classes.postJobButton} color="inherit">POST A JOB</Button>
          </Link>
        </Toolbar>        
      </AppBar>
    </div>
  );
}
